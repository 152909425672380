<template>
  <div>
    <h3 class="display-1">BLF</h3>
    <p class="body-1">
      This is not implemented on a global level yet. Each domain will retrieve all contacts during provision.<br>
      Later, there will be individual acrobits settings for each domain.
    </p>
    <v-toolbar flat color="transparent" class="my-3">
      <v-spacer/>
      <v-text-field
          placeholder="Search..."
          append-icon="mdi-magnify"
          filled
          rounded
          dense
          hide-details
          single-line
      />
    </v-toolbar>
    <v-data-table
        :headers="headers"
        :items="items"
        :search=search
    >

    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "blf",
  data() {
    return{
      headers:[
        {text: 'Name', value: 'name'},
        {text: 'extension', value: 'extension'}
      ],
      items: [],
      search: ''
    }
  },
  beforeMount() {
    this.getExtensions()
  },
  methods:{
    getExtensions() {
      this.$store.get('/api/remote-phonebook', {params: this.$store.state.domains.domain}).then(res=>{
        console.log(res.data)
      })
    }
  }
}
</script>

<style scoped>

</style>